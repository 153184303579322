/*
:root {
  --color-primary-100: #FCE9EB;
  --color-primary-200: #F6BCC3;
  --color-primary-300: #F08F9A;
  --color-primary-400: #EA6272;
  --color-primary: #E3354A;
  --color-primary-600: #CA1C31;
}
*/

$color-primary-100: var(--color-primary-100, #FBF5EE);
$color-primary-200: var(--color-primary-200, #F2E1CC);
$color-primary-300: var(--color-primary-300, #EACEAA);
$color-primary-400: var(--color-primary-400, #E1BA88);
$color-primary: var(--color-primary, #DEB37C);
$color-primary-600: var(--color-primary-600, #d19548);
$color-secondary: var(--color-secondary, black);
$color-error: var(--color-error, #F75555);
$color-background-red: var(--color-bg-red, #FFF5F5);
$color-background-yellow: var(--color-bg-yellow, #FFFEE0);
//alerts
$color-green: #4AAF57;
$color-green-alert: #dbf8e6;
$color-red-alert: #fddddd;
$color-primary-50: rgba(63, 81, 181, 0.5);

// Body
$body-color-bg: white;
$body-color-text: var(--color-secondary, #000);
$color-text: black;
// Toolbar
$toolbar-height: 56px;

// Sidebar
$sidebar-width: 64px;
// Footer
$footer-height: 49px;

$transition-all: all 0.15s ease-out;

$border-color: #424242;
$grey: #e0e0e0;
// Buttons
$button-color-primary: $color-primary;
$button-color-secondary: $color-primary-100;
$button-hover-color-primary: $color-primary;
// links

$color-link: #b84c16
